import React from "react";
import Title from "../configurations/title";
import {
  List,
  Responsive,
  SimpleList,
  Datagrid,
  TextField,
  DateField,
  downloadCSV,
} from "react-admin";
import CrudUpdateManyButton from "../components/crudUpdateManyButton";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const PostBulkActionButtons = props => (
  <CrudUpdateManyButton {...props} label="Send shipped email" resource="orders/bulk/shipped" />
);

const exporter = orders => {
  const ordersForExport = orders.map(order => {
      const { address, billingDetails, activationCodes, accountId, ...orderForExport } = order;
      orderForExport.line1 = address.line1;
      orderForExport.line2 = address.line2;
      orderForExport.city = address.city;
      orderForExport.postalCode = address.postalCode;
      orderForExport.country = address.country;
      orderForExport.codes = activationCodes.join(",");
      return orderForExport;
  });
  const csv = convertToCSV({
      data: ordersForExport,
      fields: ["id", "shippedAt", "orderType", "numberProducts", "name", "email", "line1", "line2", "city", "postalCode", "country", "coupon", "codes"]
  });
  downloadCSV(csv, "orders");
};

const AddressField = ({ record = {} }) => {
  return <span>{Object.values(record.address).filter(it => it).join(", ")}</span>;
};

const NumberProductsField = ({ record = {} }) => {
  return <span title={record.activationCodes.join(",")}>{record.numberProducts}</span>;
};

export const OrdersList = ({ ...props }) => (
  <List {...props} title={<Title />} bulkActionButtons={<PostBulkActionButtons />} exporter={exporter}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.orderType}
          secondaryText={record => record.id}
          tertiaryText={record => record.createdAt}
        />
      }
      medium={
        <Datagrid>
          <TextField source="id" sortable={false} />
          <DateField source="shippedAt" label="Shipped" showTime sortable={false} />
          <TextField source="orderType" sortable={false} />
          <NumberProductsField label="Number of Products" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextField source="email" sortable={false} />
          <AddressField label="Address" sortable={false} />
          <TextField source="coupon" sortable={false} />
        </Datagrid>
      }
    />
  </List>
);
