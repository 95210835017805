import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  CREATE,
  UPDATE,
  DELETE,
  UPDATE_MANY
} from "react-admin";
import { stringify } from "query-string";

export default (API_URL, httpClient) => {    
  const convertRESTRequestToHTTP = (type, resource, params) => {
    let url = "";
    const options = {};
   
    // Default
    switch (type) {
      case GET_MANY: {
        url = `${API_URL}/${resource}`;
        break;
      }
      case GET_LIST: {
        const query = {};

        // Build query
        if ( params.pagination && params.pagination.page ) {
          query.page = params.pagination.page - 1; // starts at 0
        }
        if ( params.pagination && params.pagination.perPage ) {
          query.size = params.pagination.perPage;
        }
        if ( params.sort && params.sort.field && params.sort.order ) {
          query.sort = `${params.sort.field},${params.sort.order}`;
        }

        // Filter parameters
        if ( params.filter ) {
          // Filter for activation codes
          if ( resource === "codes" ) {
            if ( params.filter.code ) {
              query.code = params.filter.code;
            }

            if ( params.filter.status ) {
              query.status = params.filter.status;
            }

            if ( params.filter.order ) {
              query.order = params.filter.order;
            }
          } else if (resource === "accounts/scans" || resource === "accounts/users" || resource === "specialists") {
            if ( params.filter.email ) {
              query.email = params.filter.email;
            }
            if ( params.filter.name ) {
              query.name = params.filter.name;
            }
            if ( params.filter.specialist !== undefined ) {
              query.specialist = params.filter.specialist;
            }
          } else if (resource === "specialists/users") {
            if ( params.filter.specialistEmail ) {
              query.specialistEmail = params.filter.specialistEmail;
            }
            if ( params.filter.userEmail ) {
              query.userEmail = params.filter.userEmail;
            }
          }
        }

        url = `${API_URL}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_ONE: {
        url = `${API_URL}/${resource}/${params.id}`;
        break;
      }
      case CREATE: {
        switch (resource) {
          // When creating new user create a marketing account by default
          case "accounts/users": {
            url = `${API_URL}/accounts/marketing`;
            break;
          }
          default: {
            url = `${API_URL}/${resource}`;
            break;
          }
        }
        
        options.method = "POST";
        options.data = params.data;
        break;
      }
      case UPDATE: {
        url = `${API_URL}/${resource}/${params.id}`;
        options.method = "PUT";
        options.data = params.data;
        break;
      }
      case DELETE: {
        url = `${API_URL}/${resource}/${params.id}`;
        options.method = "DELETE";
        break;
      }
      case UPDATE_MANY: {
        const query = { filter: JSON.stringify({ ids: params.ids }) };
        url = `${API_URL}/${resource}?${stringify(query)}`;
        options.method = "PATCH";
        options.data = params.data;
        break;
      }
      default: {
        throw new Error(`Unsupported fetch action type ${type}`);
      }
    }
    
    return { url, options };
  };

  const convertHTTPResponseToREST = (response, type, resource, params) => {
    const json = response.data || {};
    const headers = response.headers || new Headers();

    // Default
    switch (type) {
      case GET_LIST:
      case GET_MANY: {
        let content = json.content && json.content instanceof Array ? json.content : json;

        switch (resource) {
          // Setting id=accountId to allow to be used as selectedIds
          case "accounts/users": {
            content = content.map((it) => {
              it.id = it.accountId;
              return it;
            });
            break;
          }
          case "codes": {
            content = content.map((it) => {
              it.id = it.code;
              return it;
            });
            break;
          }
          default: {
            break;
          }
        }

        return {
          data: content.map(x => x),
          total: parseInt(headers.get("x-total-count") || content.length, 10),
        };
      }
      case CREATE: {
        return { data: { ...params.data, id: json.id } };
      }
      case UPDATE: {
        return { data: { ...params.data, id: parseInt(params.id, 10) } };
      }
      case UPDATE_MANY: {
        return { data: [] };
      }
      default: {
        return { data: json };
      }
    }
  };

  return (type, resource, params) => {
    const { url, options } = convertRESTRequestToHTTP(type, resource, params);

    return httpClient(url, options).then(response => {
      return convertHTTPResponseToREST(response, type, resource, params);
    }
    );
  };
};
