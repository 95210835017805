import React from "react";
import Title from "../configurations/title";
import {
  List,
  Responsive,
  SimpleList,
  Datagrid,
  TextField,
  DateField,
  Create,
  NumberInput,
  SimpleForm,
  Filter,
  required,
  TextInput,
  SelectInput,
} from "react-admin";

export const ActivationCodeList = ({ ...props }) => (
    <List {...props} title={<Title />} bulkActions={false} filters={<ActivationCodeFilter />}>
      <Responsive
        small={
          <SimpleList
            primaryText={record => record.code}
            secondaryText={record => record.status}
            tertiaryText={record => record.orderId}
          />
        }
        medium={
          <Datagrid>
            <TextField source="code" sortable={false} />
            <TextField source="status" label="Code Status" sortable={false} />
            <TextField source="orderId" label="Order Id" sortable={false} />
            <DateField source="createdAt" showTime sortable={false} />
            <DateField source="updatedAt" showTime sortable={false} />
          </Datagrid>
        }
      />
    </List>
);

export const activationCodeCreate = ({ ...props }) => (
    <Create title={<Title />} {...props}>
      <SimpleForm redirect="list">
        <NumberInput source="count" validate={required()} />
      </SimpleForm>
    </Create>
  );

export const ActivationCodeFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Code" source="code" alwaysOn />
        <TextInput label="Order" source="order" />
        <SelectInput source="status" choices={[
            { id: 'Created', name: 'Created' },
            { id: 'Claimed', name: 'Claimed' },
            { id: 'Used', name: 'Used' },
        ]} />
    </Filter>
);
