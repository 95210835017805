import React from "react";
import { createBrowserHistory } from "history";
import { Admin, Resource } from "react-admin";
import { Route } from "react-router-dom";
import { AuthClient, RestClient } from "./clients";
import UserIcon from "@material-ui/icons/People";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import ScansIcon from "@material-ui/icons/PhoneAndroid";
import SpecialistsIcon from "@material-ui/icons/LocalHospital";
import UserSpecialistsIcon from "@material-ui/icons/AssignmentInd";

import { LoginRedirect } from "./resources/login";
import { LogoutRedirect } from "./resources/logout";
import { LogoutButton } from "./resources/logoutButton";
import { OrdersList } from "./resources/orders";
import { UsersList, UsersCreate, UsersShow } from "./resources/users";
import { activationCodeCreate, ActivationCodeList } from "./resources/activationCodes";
import { ScansShow, ScansList } from "./resources/scans";
import { SpecialistsEdit, SpecialistsCreate, SpecialistsList } from "./resources/specialists";
import { UserSpecialistsShow, UserSpecialistsCreate, UserSpecialistsList } from "./resources/userSpecialists";

// History to remove links with /#/resource
const history = createBrowserHistory();

// Custom routes
const customRoutes = [
  <Route exact path="/logout" component={LogoutRedirect} key={0} />,
];

const App = () => (
  <Admin
    dataProvider={RestClient}
    authProvider={AuthClient}
    loginPage={LoginRedirect}
    logoutButton={LogoutButton}
    history={history}
    customRoutes={customRoutes}
  >
    <Resource 
      name="orders"
      list={OrdersList}
    />
    <Resource 
      name="accounts/users"
      options={{ label: "Users" }}
      list={UsersList}
      show={UsersShow}
      create={UsersCreate}
      icon={UserIcon}
    />
    <Resource 
      name="accounts/scans"
      options={{ label: "Scans" }}
      list={ScansList}
      show={ScansShow}
      icon={ScansIcon}
    />
    <Resource 
      name="codes"
      options={{ label: "Activation Codes" }}
      list={ActivationCodeList}
      create={activationCodeCreate}
      icon={BeenhereIcon}
    />
    <Resource 
      name="specialists/users"
      options={{ label: "User Practitioners" }}
      list={UserSpecialistsList}
      create={UserSpecialistsCreate}
      show={UserSpecialistsShow}
      icon={UserSpecialistsIcon}
    />
    <Resource 
      name="specialists"
      options={{ label: "Practitioners" }}
      list={SpecialistsList}
      create={SpecialistsCreate}
      edit={SpecialistsEdit}
      icon={SpecialistsIcon}
    />
  </Admin>
);

export default App;
