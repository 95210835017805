import React, { useState } from "react";
import Title from "../configurations/title";
import {
  List,
  Responsive,
  SimpleList,
  Datagrid,
  TextField,
  DateField,
  Create,
  SimpleForm,
  TextInput,
  required,
  Show,
  NumberField,
  BooleanField,
  TabbedShowLayout,
  Tab,
  CREATE,
  Filter,
  FunctionField,
  Link
} from "react-admin";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import CrudUpdateManyButton from "../components/crudUpdateManyButton";
import { connect } from 'react-redux';

const PostBulkActionButtons = props => (
  <CrudUpdateManyButton {...props} label="Activate account" resource="accounts/bulk/activation" />
);

export const UsersListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" />
    <TextInput label="Name" source="name" />
  </Filter>
);

export const UsersList = ({ ...props }) => (
  <List {...props} title={<Title />} bulkActionButtons={<PostBulkActionButtons />} filters={<UsersListFilter />}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.email}
          secondaryText={record => record.id}
          tertiaryText={record => record.createdAt}
        />
      }
      medium={
        <Datagrid rowClick="show">
          <TextField source="accountId" sortable={false} />
          <DateField source="accountCreatedAt" label="Created" showTime sortable={false} />
          <DateField source="accountActivatedAt" label="Activated" showTime sortable={false} />
          <TextField source="email" sortable={false} />
          <TextField source="name" sortable={false} />
        </Datagrid>
      }
    />
  </List>
);

/**
 * Docs: https://marmelab.com/react-admin/doc/2.9/Actions.html#adding-side-effects-to-actions
 */
const addBalance = (id, balance, basePath) => ({
  type: "ADD_BALANCE",
  payload: { id, data: { balance } },
  meta: {
    fetch: CREATE,
    resource: `accounts/users/${id}/balance`,
    onSuccess: {
      notification: {
        body: "Balance added sucesfully",
        level: "info"
      },
      redirectTo: `/accounts/users/${id}/show`,
      refresh: true,
      basePath
    },
    onFailure: {
      notification: {
        body: "Error adding balance",
        level: "warning"
      },
    },
  },
});

const AddBalance = props => {
  const [balance, setBalance] = useState(1);

  const onSubmit = () => props.addBalance(props.record.id, balance);

  return (
    <FormGroup row>
      <Input type="number" defaultValue={balance} onChange={e => setBalance(parseInt(e.target.value, 10))} />
      <Button variant="contained" color="primary" onClick={onSubmit}>Add Balance</Button>
    </FormGroup>
  );
};

const AddBalanceForm = connect(null, { addBalance })(AddBalance);

export const UsersShow = ({ ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="id" />
        <TextField source="email" />
        <TextField source="userId" />
        <TextField source="name" />
        <NumberField source="balance" label="Scans Balance" />
        <TextField source="accountStatus" />
        <FunctionField label="Last scan" render={record => <Link to={`/accounts/scans/${record.lastScanId}/show`}>{record.lastScanId}</Link>} />
        <DateField source="lastScanDateUtc" showTime />
        <TextField source="lastPlaqueCoverage" />
        <NumberField source="ongoingScanId" />
        <DateField source="nextScanScheduleUtc" showTime />
        <DateField source="nextScanScheduleLocalDateTime" showTime />
        <TextField source="pendingScanSchedule" />
        <BooleanField source="betaVersionEnlisted" />
        <TextField source="customerRef" />
        <DateField source="accountCreatedAt" showTime />
        <DateField source="accountActivatedAt" showTime />
      </Tab>
      <Tab label="Add Scan Balance">
        <AddBalanceForm />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const UsersCreate = ({ ...props }) => (
  <Create title={<Title />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="email" type="email" validate={required()} />
      <TextInput source="name" validate={required()} />
      <TextInput source="line1" label="Address" validate={required()} />
      <TextInput source="city" validate={required()} />
      <TextInput source="postalCode" label="Postalcode" validate={required()} />
      <TextInput source="country" validate={required()} />
    </SimpleForm>
  </Create>
);
