
import { parse } from "query-string";
import environment from "../configurations/environment";
import { AuthClient } from "../clients";
import { AUTH_LOGIN } from "react-admin";

const getAccessTokenFromHash = (location) => {
  if ( location.hash ) {
    const hash = parse(window.location.hash);
    return hash.id_token && hash.expires_in ? hash : undefined;
  }

  return undefined;
};

export const LoginRedirect = () => {
  const token = getAccessTokenFromHash(window.location);

  if ( token ) {
    console.log("Received token from AWS", token);
    AuthClient(AUTH_LOGIN, token).then(() => window.location.replace("/"));
    return "Logging in...";
  } else {
    window.location.replace(environment.authUrl); // Simulate an HTTP redirect
    return "Redirecting...";
  }
};
