import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
});

const Title = ({ classes, ...props }) => (
  "UUNN Backoffice"
);

export default withStyles(styles)(Title);
