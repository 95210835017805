import React from "react";
import Title from "../configurations/title";
import {
  List,
  Responsive,
  SimpleList,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  Create,
  SimpleForm,
  required,
  Edit
} from "react-admin";

export const SpecialistsListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" />
    <TextInput label="Name" source="name" />
  </Filter>
);

export const SpecialistsList = ({ ...props }) => (
  <List {...props} title={<Title />} bulkActions={false} filters={<SpecialistsListFilter />}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.userId}
          secondaryText={record => record.id}
          tertiaryText={record => record.createdAt}
        />
      }
      medium={
        <Datagrid rowClick="show">
          <TextField source="id" sortable={false} />
          <DateField source="createdAt" label="Created" showTime sortable={false} />
          <DateField source="updatedAt" label="Updated" showTime sortable={false} />
          <TextField source="email" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextField source="clinicName" sortable={false} />
        </Datagrid>
      }
    />
  </List>
);

export const SpecialistsEdit = ({ ...props }) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextField source="createdAt" />
      <TextField source="updatedAt" />
      <TextField source="email" />
      <TextField source="userId" />
      <TextInput source="name" validate={required()} />
      <TextInput source="clinicName" />
      <TextInput source="line1" />
      <TextInput source="line2" />
      <TextInput source="city" />
      <TextInput source="postalCode" />
      <TextInput source="country" />
    </SimpleForm>
  </Edit>
);

export const SpecialistsCreate = ({ ...props }) => (
  <Create title={<Title />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="email" type="email" validate={required()} />
      <TextInput source="name" validate={required()} />
      <TextInput source="clinicName" />
      <TextInput source="line1" />
      <TextInput source="line2" />
      <TextInput source="city" />
      <TextInput source="postalCode" />
      <TextInput source="country" />
    </SimpleForm>
  </Create>
);