import React from "react";
import Title from "../configurations/title";
import {
  List,
  Responsive,
  SimpleList,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  Create,
  SimpleForm,
  required,
  Show,
  SimpleShowLayout,
  DeleteButton,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput
} from "react-admin";

export const UserSpecialistsListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Specialist Email" source="specialistEmail" />
    <TextInput label="User Email" source="userEmail" />
  </Filter>
);

export const UserSpecialistsList = ({ ...props }) => (
  <List {...props} title={<Title />} bulkActions={false} filters={<UserSpecialistsListFilter />}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => `${record.specialistEmail} - ${record.userEmail}`}
          secondaryText={record => record.id}
          tertiaryText={record => record.createdAt}
        />
      }
      medium={
        <Datagrid rowClick="show">
          <TextField source="id" sortable={false} />
          <DateField source="createdAt" label="Created" showTime sortable={false} />
          <DateField source="updatedAt" label="Updated" showTime sortable={false} />
          <TextField source="specialistEmail" sortable={false} />
          <TextField source="userEmail" sortable={false} />
          <TextField source="type" sortable={false} />
        </Datagrid>
      }
    />
  </List>
);

const UserSpecialistsShowActions = ({ basePath, data, resource }) => (
  <DeleteButton basePath={basePath} record={data} resource={resource} />
);

export const UserSpecialistsShow = ({ ...props }) => (
  <Show actions={<UserSpecialistsShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="createdAt" />
      <TextField source="updatedAt" />
      <TextField source="specialistId" />
      <TextField source="specialistEmail" />
      <TextField source="userId" />
      <TextField source="userEmail" />
      <TextField source="type" />
    </SimpleShowLayout>
  </Show>
);

const UserSpecialistTypes = [
  { id: "Dentist", name: "Dentist" }
];

export const UserSpecialistsCreate = ({ ...props }) => (
  <Create title={<Title />} {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput source="specialistId" reference="specialists" label="Specialist" validate={required()} perPage={100}>
        <SelectInput optionText="email" optionValue="userId" />
      </ReferenceInput>
      <ReferenceArrayInput source="userIds" reference="accounts/users" label="Users" validate={required()} perPage={100} filter={{ specialist: false }}>
        <SelectArrayInput optionText="email" optionValue="userId" />
      </ReferenceArrayInput>
      <SelectInput source="type" choices={UserSpecialistTypes} validate={required()} defaultValue="Dentist" />
    </SimpleForm>
  </Create>
);