import { AUTH_LOGOUT } from "react-admin";
import Axios from "axios";

export default (authClient) => {

  return (url, options = {}) => {
    if (!options.headers) {
      options.headers = { Accept: "application/json" };
    }
  
    // Add Authorization token
    const token = localStorage.getItem("token");
    options.headers["Authorization"] = `Bearer ${token}`;
  
    // See if token expired
    const expirationToken = localStorage.getItem("expiration");
    const now = new Date().getTime();
  
    if ( now > expirationToken ) {
      console.log(`Token expired forcing logout (now is ${now} and expired at ${expirationToken})`);
      return authClient(AUTH_LOGOUT).then(() => window.location.reload());
    } else {
      return Axios(url, options);
    }
  };
};
