import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
} from "react-admin";

export default (API_URL) => {
  return (type, params) => {
    if (type === AUTH_LOGIN) {
      const expiration = new Date().getTime() + (params.expires_in * 1000);
      localStorage.setItem("expiration", expiration);
      localStorage.setItem("token", params.id_token);
      localStorage.setItem("hash", JSON.stringify(params));

      return Promise.resolve();
    }
  
    // Called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
      localStorage.clear();
      return Promise.resolve();
    }
  
    // Called when the API returns an error
    if (type === AUTH_ERROR) {
      const { status } = params;

      if (status === 401 || status === 403) {
        return Promise.reject();
      }

      return Promise.resolve();
    }
  
    // Called when the user navigates to a new location
    if (type === AUTH_CHECK) {
      return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
    }
    
    return Promise.reject("Unknown method");
  };
};
