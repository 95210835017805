import React, { useState } from "react";
import Title from "../configurations/title";
import {
  List,
  Responsive,
  SimpleList,
  Datagrid,
  TextField,
  DateField,
  Show,
  SimpleShowLayout,
  ArrayField,
  FunctionField,
  Filter,
  TextInput,
  Link
} from "react-admin";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { HttpClient } from "../clients";
import environment from "../configurations/environment";

const ScanState = {
  CANCELED: "CANCELED",
  CREATED: "CREATED",
  WAITING_REMAINING_PHOTOS: "WAITING_REMAINING_PHOTOS",
  SUBMITTED_TO_QUEUE: "SUBMITTED_TO_QUEUE",
  PICKED_UP_FROM_QUEUE: "PICKED_UP_FROM_QUEUE",
  SUBMITTED: "SUBMITTED",
  DONE: "DONE"
};

const renderScanState = (record) => Object.values(ScanState)[record.state];

export const ScansListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" />
    <TextInput label="Name" source="name" />
  </Filter>
);

export const ScansList = ({ ...props }) => (
  <List {...props} title={<Title />} bulkActions={false} filters={<ScansListFilter />}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.userId}
          secondaryText={record => record.id}
          tertiaryText={record => record.createdAt}
        />
      }
      medium={
        <Datagrid rowClick="show">
          <TextField source="id" sortable={false} />
          <DateField source="createdAt" label="Created" showTime sortable={false} />
          <DateField source="updatedAt" label="Updated" showTime sortable={false} />
          <FunctionField render={renderScanState} label="State" sortable={false} />
          <TextField source="email" sortable={false} />
          <TextField source="name" sortable={false} />
        </Datagrid>
      }
    />
  </List>
);

const ScanDownloadButtonBase = props => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => {
    const { record } = props;

    setIsLoading(true);

    HttpClient(`${environment.api}/accounts/scans/${record.id}/download`)
      .then(res => {
        const link = document.createElement("a");
        link.href = res.data.link;
        link.click();
      })
      .catch((e) => {
        console.error("Error downloading zip file", e);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Button variant="contained" color="primary" onClick={handleClick}>
      Download Scan Output
      {isLoading && "..."}
    </Button>
  );
};

ScanDownloadButtonBase.propTypes = {
  record: PropTypes.object,
};

const ScanDownloadButton = connect(null)(ScanDownloadButtonBase);

export const ScansShow = ({ ...props }) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="createdAt" />
      <TextField source="updatedAt" />
      <FunctionField render={renderScanState} label="State" />
      <FunctionField label="User" render={record => <Link to={`/accounts/users/${record.userId}/show`}>{record.email}</Link>} />
      <TextField source="instanceId" />
      <ArrayField source="states">
        <Datagrid>
          <DateField source="timestamp_string" showTime />
          <FunctionField render={renderScanState} label="State" />
        </Datagrid>
      </ArrayField>
      <ScanDownloadButton />
    </SimpleShowLayout>
  </Show>
);
