import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, crudUpdateMany } from "react-admin";

class CrudUpdateManyButton extends Component {
  handleClick = () => {
    const { basePath, crudUpdateMany, resource, selectedIds, data } = this.props;
    crudUpdateMany(resource, selectedIds, data, basePath);
  };

  render() {
    const { label } = this.props;
    return (
      <Button label={label} onClick={this.handleClick} />
    );
  }
}

export default connect(
  undefined,
  { crudUpdateMany }
)(CrudUpdateManyButton);
